<template>
  <PageContainer
    :title="$translations.documents.title"
    :back="closeButton"
    :submit="action"
  >
    <template v-slot:content>
      <div class="page-segment padding-bottom-zero">
        <div class="page-segment-content">
          <template v-if="loading">
            <Skeleton
              v-for="(i, k) of 5"
              :key="k"
              width="100%"
              height="24px"
              borderRadius="8px"
              class="margin-bottom"
            />
          </template>

          <div class="documents" v-else>
            <CheckboxGroup
              :checkboxs="documents"
              type="fullcheck-gray-v2"
              textClass="content-title"
              :reverse="true"
              @change="handlerDocuments"
              v-if="$route.query?.appointmentId"
            />
            <template v-else>
              <div
                class="documents-item content-title"
                v-for="(item, index) of documents"
                :key="index"
                v-text="item.title[language.lang]"
                @click="showDetail(item)"
              />
            </template>
          </div>
        </div>
      </div>
    </template>
    <template
      v-slot:footer
      v-if="$route.query.appointmentId && documentsSelected.length > 0"
    >
      <div class="documents-selected content-title" v-text="selectedText" />
    </template>
  </PageContainer>
</template>
<script>
import {
  PageContainer,
  Instruments,
  CheckboxGroup,
  Skeleton
} from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
export default {
  name: 'Documents',
  components: {
    PageContainer,
    CheckboxGroup,
    Skeleton
  },
  data () {
    let action = null
    if (this.$route.query?.appointmentId) {
      action = {
        text: 'Compartir',
        disabled: true,
        callback: () => this.handlerSubmit()
      }
    }
    return {
      documents: [],
      documentsRaw: [],
      loading: true,
      action,
      documentsSelected: [],
      closeButton: {
        callback: () => this.handlerClose()
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const documentList = await Instruments.getDocuments()
      this.documentsRaw = [...documentList]
      this.documents = documentList.map((item) => {
        if (!item.title) {
          return {
            title: {
              en: 'No title',
              es: 'Sin título',
              pt: 'Sem título'
            },
            text: 'Sin título',
            key: item.key,
            code: item.key
          }
        }

        return {
          title: item.title.text,
          text: item.title.text[this.language.lang],
          key: item.key,
          code: item.key
        }
      })
      this.loading = false
    },
    showDetail (item) {
      this.$router.push({
        name: 'Document',
        params: {
          key: item.key
        },
        query: {
          back: this.$route.fullPath
        }
      })
    },
    handlerClose () {
      if (this.$route.query.back) {
        this.$router.replace({
          path: this.$route.query.back
        })
      } else {
        this.$router.replace({ name: 'Home' })
      }
    },
    handlerSubmit () {
      const documentsFlow = []

      this.documentsRaw.forEach((document) => {
        this.documentsSelected.forEach((item) => {
          if (document.key === item.key) {
            documentsFlow.push({
              ...document,
              reference_id: this.$route.query.appointmentId,
              reference_type: 'INSTRUMENT',
              status: 'PENDING',
              answer: []
            })
          }
        })
      })

      Instruments.shareInstruments(documentsFlow).then(() => {
        this.$toast({
          text: this.$translations.documents.success,
          severity: 'success'
        })
        this.handlerClose()
      })
    },
    handlerDocuments (item) {
      this.documentsSelected = [...item]
      this.action.disabled = !(this.documentsSelected.length > 0)
    }
  },
  computed: {
    ...mapGetters({
      language: 'language'
    }),
    selectedText () {
      return `${this.documentsSelected.length} ${
        this.documentsSelected.length > 1 ? 'documentos' : 'documento'
      } seleccionado`
    }
  }
}
</script>
<style lang="sass" scoped>
.documents
  display: flex
  flex-direction: column
  &-item
    border-bottom: 1px solid var(--gray-10)
    padding: 12px 0
    cursor: pointer
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    position: relative
    overflow: hidden
    max-height: calc(3 * 1.25em)
    display: -webkit-box
    &:last-child
      border-bottom: none

::v-deep
  .page-container
    &-action
      .documents-selected
        border-bottom: 1px solid var(--gray-20)
        margin-left: -16px
        margin-right: -16px
        margin-bottom: 16px
        text-align: center
        padding-bottom: 16px
</style>
